import { environmentConfig } from "@src/appV2/environment";

export const GET_DISTANCE_BETWEEN_TWO_GEOLOCATIONS = `${environmentConfig.REACT_APP_LOCATION_SERVICE_URL}/route/matrix`;

export const DEFAULT_MAX_DISTANCE_ALLOWED_FOR_LOCATION_VERIFICATION_IN_MILES = 130;

export const GET_GEOLOCATION_TIMEOUT_IN_MILLISECONDS = 6000;

export const MIN_ANDROID_OS_WITH_ALWAYS_ALLOW_OPTION = "10.0.0";

export enum UrgentShiftLocalStorage {
  GENERAL_PRIMER_PAGE_VIEWED = "generalPrimerPageViewed",
  LAST_LOCATION_PERMISSION_STATUS = "lastLocationPermissionStatus",
  USER_DISABLED_FULL_LOCATION_PERMISSION = "userDisabledFullLocationPermission",
}

export enum LocationAccuracyStatus {
  FULL = "full",
  REDUCED = "reduced",
}
