import { get } from "@src/appV2/api/api";
import { z } from "zod";

import { GET_DISTANCE_BETWEEN_TWO_GEOLOCATIONS } from "../constant";

const distanceBetweenTwoGeoLocationSchema = z.object({
  distance: z.number(),
  duration: z.number(),
});

export const distanceBetweenTwoGeoLocationResponse = z.array(
  z.array(distanceBetweenTwoGeoLocationSchema)
);

export type DistanceBetweenTwoGeoLocationResponse = z.infer<
  typeof distanceBetweenTwoGeoLocationSchema
>;

export interface GetDistanceBetweenTwoGeoLocationPayload {
  origins: string;
  destinations: string;
}

export async function getDistanceBetweenTwoGeoLocations(
  queryParams: GetDistanceBetweenTwoGeoLocationPayload
) {
  const { data: responseDistances } = await get({
    url: GET_DISTANCE_BETWEEN_TWO_GEOLOCATIONS,
    queryParams,
    responseSchema: distanceBetweenTwoGeoLocationResponse,
  });
  /**
   * responseDistances is an array of array with object {distance, duration}
   * since we are only passing one set of origin and destination, we get result as first element of array
   */
  return responseDistances?.[0]?.[0];
}
