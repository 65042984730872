import { type GeoLocation } from "@src/appV2/Location/index";

const METER_TO_MILE_RATIO = 0.000_621_371;
/**
 * Mean Earth radius https://en.wikipedia.org/wiki/Earth_radius
 */
const EARTHS_RADIUS_IN_METERS = 6_371_008;
const EARTHS_RADIUS_IN_MILES = EARTHS_RADIUS_IN_METERS * METER_TO_MILE_RATIO;
const STRAIGHT_LINE_CORRECTION_FACTOR = 1.4;

export function calculateGeoDistanceInMiles(
  geoLocation1: GeoLocation,
  geoLocation2: GeoLocation
): number {
  const { latitude: latitude1, longitude: longitude1 } = geoLocation1;
  const { latitude: latitude2, longitude: longitude2 } = geoLocation2;

  const latitudeDifferenceInRadians = ((latitude2 - latitude1) * Math.PI) / 180;
  const longitudeDifferenceInRadians = ((longitude2 - longitude1) * Math.PI) / 180;
  /**
   * https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
   */
  const a =
    Math.sin(latitudeDifferenceInRadians / 2) * Math.sin(latitudeDifferenceInRadians / 2) +
    Math.cos((latitude1 * Math.PI) / 180) *
      Math.cos((latitude2 * Math.PI) / 180) *
      Math.sin(longitudeDifferenceInRadians / 2) *
      Math.sin(longitudeDifferenceInRadians / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Number((c * EARTHS_RADIUS_IN_MILES).toFixed(2));
}

export function calculateEstimatedDrivingDistanceInMiles(
  geoLocation1: GeoLocation,
  geoLocation2: GeoLocation
): number {
  return (
    Math.round(
      calculateGeoDistanceInMiles(geoLocation1, geoLocation2) *
        STRAIGHT_LINE_CORRECTION_FACTOR *
        100
    ) / 100
  );
}
