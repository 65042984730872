import HyperTrack from "hypertrack-sdk-ionic-capacitor";

export async function getUserCurrentLocation(): Promise<{ latitude?: number; longitude?: number }> {
  const location = await HyperTrack.getLocation();
  if (location.type === "success") {
    return {
      latitude: location.value.latitude,
      longitude: location.value.longitude,
    };
  }

  return {};
}
