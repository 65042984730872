import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { LicenseStatuses } from "./types";

export interface UseGetLicensesProps {
  workerId: string;
}

const licenseSchema = z.object({
  id: z.string(),
  workerUserId: z.string(),
  qualification: z.string(),
  state: z.string(),
  multiState: z.boolean(),
  number: z.string(),
  expiresAt: z.string().nullish(),
  status: z.nativeEnum(LicenseStatuses),
  createdAt: z.string(),
  updatedByType: z.string().nullable(),
  rejectedAt: z.string().nullable(),
  rejectionReason: z.string().nullable(),
  notifyRejection: z.boolean().nullable(),
  rejectionNoteForWorker: z.string().nullable(),
  reviewedAt: z.string().nullable(),
});

export type License = z.infer<typeof licenseSchema>;

const licensesResponseSchema = z.array(licenseSchema);

type LicensesResponse = z.infer<typeof licensesResponseSchema>;

export const getLicensesPath = (workerId: string) => `/workers/${workerId}/licenses`;

export function useGetLicenses(
  props: UseGetLicensesProps,
  options: UseGetQueryOptions<LicensesResponse> = {}
): UseQueryResult<LicensesResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_LICENSE_MANAGER_SERVICE_URL}${getLicensesPath(
      props.workerId
    )}`,
    responseSchema: licensesResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_LICENSES_FAILURE,
      userErrorMessage: options.userErrorMessage,
    },
    ...options,
  });
}
