import { Diagnostic } from "@ionic-native/diagnostic";

import { getCurrentLocationStatus } from "../utils";

export async function isDeviceLocationPermissionGranted() {
  const locationAuthorizationStatus: unknown = await Diagnostic.getLocationAuthorizationStatus();
  return (
    locationAuthorizationStatus === Diagnostic.permissionStatus.GRANTED ||
    locationAuthorizationStatus === Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE
  );
}

export async function isDeviceLocationAlwaysAllowPermissionGranted() {
  const locationSettings = await getCurrentLocationStatus();
  return locationSettings.isLocationServicesEnabled;
}
