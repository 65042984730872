import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

export interface UseGetLicensesCountProps {
  workerId: string;
}

const licensesCountResponseSchema = z.object({
  PENDING: z.number().optional(),
  ACTIVE: z.number().optional(),
  REJECTED: z.number().optional(),
  EXPIRING: z.number().optional(),
  EXPIRED: z.number().optional(),
});

export type LicensesCount = z.infer<typeof licensesCountResponseSchema>;

export const GET_LICENSES_COUNT_PATH = "/workers/:workerId/licenses/count";

export const getLicensesCountUrl = (workerId: string) =>
  `${environmentConfig.REACT_APP_LICENSE_MANAGER_SERVICE_URL}${GET_LICENSES_COUNT_PATH.replace(
    ":workerId",
    workerId
  )}`;

export function useGetLicensesCount(
  props: UseGetLicensesCountProps,
  options: UseGetQueryOptions<LicensesCount> = {}
): UseQueryResult<LicensesCount> {
  return useGetQuery({
    url: getLicensesCountUrl(props.workerId),
    responseSchema: licensesCountResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_LICENSES_COUNT_FAILURE,
    },
    ...options,
  });
}
