export enum LicenseStatuses {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  REJECTED = "REJECTED",
  ARCHIVED = "ARCHIVED",
  /**
   * This status is computed on client, it does not exist on backend
   */
  EXPIRING = "EXPIRING",
}
