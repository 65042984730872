import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation } from "@tanstack/react-query";

import { getDistanceBetweenTwoGeoLocations } from "../api";
import { DEFAULT_MAX_DISTANCE_ALLOWED_FOR_LOCATION_VERIFICATION_IN_MILES } from "../constant";
import { getDeviceGeoLocation } from "../deviceLocation/geoLocation";
import { type GeoLocation } from "../types";
import { formatGeoLocationToLatitudeLongitude } from "../utils";

interface UseLocationVerificationOptions {
  originCoordinates: GeoLocation;
  maxAllowedDistanceFromAddress?: number;
}

export function useCbhLocationVerification(options: UseLocationVerificationOptions) {
  const maxAllowedDistanceFromAddress = useCbhFlag(
    CbhFeatureFlag.MAX_DISTANCE_ALLOWED_FOR_LOCATION_VERIFICATION_IN_MILES,
    {
      defaultValue: DEFAULT_MAX_DISTANCE_ALLOWED_FOR_LOCATION_VERIFICATION_IN_MILES,
    }
  );
  return useMutation({
    mutationFn: async () => {
      try {
        const { geoLocation } = await getDeviceGeoLocation();
        const distanceBetweenGeolocationsResponseInMiles = await getDistanceBetweenTwoGeoLocations({
          origins: formatGeoLocationToLatitudeLongitude(options.originCoordinates),
          destinations: formatGeoLocationToLatitudeLongitude(geoLocation),
        });
        return {
          isLocationVerified:
            (distanceBetweenGeolocationsResponseInMiles?.distance ?? Number.POSITIVE_INFINITY) <=
            (options.maxAllowedDistanceFromAddress ?? maxAllowedDistanceFromAddress),
          distanceBetweenGeolocationsResponseInMiles,
          deviceGeoLocation: geoLocation,
          originCoordinates: options.originCoordinates,
        };
      } catch (error: unknown) {
        return {
          isLocationVerified: false,
          error,
        };
      }
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.LOCATION_VERIFICATION_CHECK_FAILURE,
    },
  });
}
