import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { LicenseStatuses } from "@src/appV2/Licenses/api/types";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

export interface UseGetLicenseDetailsProps {
  licenseId: string;
  workerId: string;
}

const licenseDetailsSchema = z.object({
  id: z.string(),
  workerUserId: z.string(),
  qualification: z.string(),
  state: z.string(),
  multiState: z.boolean(),
  number: z.string(),
  expiresAt: z.string().nullish(),
  hasPendingLicense: z.boolean(),
  status: z.nativeEnum(LicenseStatuses),
  createdAt: z.string(),
  updatedByType: z.string().nullish(),
  rejectedAt: z.string().nullish(),
  rejectionReason: z.string().nullish(),
  notifyRejection: z.boolean().nullish(),
  rejectionNoteForWorker: z.string().nullish(),
  reviewedAt: z.string().nullish(),
});

type LicenseDetailsResponse = z.infer<typeof licenseDetailsSchema>;

export const getLicenseDetailsPath = (workerId: string, licenseId: string) =>
  `/workers/${workerId}/licenses/${licenseId}`;

export function useGetLicenseDetails(
  props: UseGetLicenseDetailsProps,
  options: UseGetQueryOptions<LicenseDetailsResponse> = {}
): UseQueryResult<LicenseDetailsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_LICENSE_MANAGER_SERVICE_URL}${getLicenseDetailsPath(
      props.workerId,
      props.licenseId
    )}`,
    responseSchema: licenseDetailsSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_LICENSE_DETAILS_FAILURE,
      userErrorMessage: options.userErrorMessage,
    },
    ...options,
  });
}
